import { Modal } from 'antd';
import React, { memo } from 'react';
import NotiForm from './NotiForm';

const NotificationDetailsModal = memo(({ visible, data, onCancel, onSuccess }) => {
   return (
      <Modal
         className="common-modal"
         wrapClassName="common-modal-container"
         visible={visible}
         width={630}
         closable={false}
         maskClosable={false}
         footer={null}
         destroyOnClose={true}
      >
         <NotiForm data={data} onCancel={onCancel} onSuccess={onSuccess} />
      </Modal>
   );
});

export default NotificationDetailsModal;
