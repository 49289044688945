import React from 'react';

const IconAdd2 = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M8 1V15"
         stroke="currentColor"
         strokeMiterlimit={10}
         strokeLinejoin="round"
      />
      <path
         d="M1 8H15"
         stroke="currentColor"
         strokeMiterlimit={10}
         strokeLinejoin="round"
      />
   </svg>
);

export default IconAdd2;