import React, { memo } from 'react';
import { Popconfirm } from 'antd';
import IconTrash from '@icons/IconTrash';

const PopConfirm = memo(({
   className, isOpen, okText, cancelText, children, title, placement, zIndex, disabled,
   onVisibleChange, onConfirm, onCancel,
}) => {
   return (
      <Popconfirm
         visible={isOpen}
         overlayClassName={`common-popconfirm ${className || ''}`}
         placement={placement || 'bottomRight'}
         title={title || 'Are you sure to delete?'}
         onConfirm={onConfirm}
         onCancel={onCancel}
         okText={okText || 'Delete'}
         cancelText={cancelText || 'Cancel'}
         destroyTooltipOnHide={true}
         onVisibleChange={onVisibleChange}
         zIndex={zIndex}
         mouseEnterDelay={0}
         mouseLeaveDelay={0}
         icon={<IconTrash />}
         disabled={disabled}
      >
         {children}
      </Popconfirm>
   );
});

export default PopConfirm;