import Checkbox from '@components/Checkbox';
import PopupSearchable from '@components/PopupSearchable';
import IconArrowDown2 from '@icons/IconArrowDown2';
import IconCancel12 from '@icons/IconCancel12';
import { userService } from '@services/userService';
import { escapesValue, kmpSearch } from '@utils/common';
import constant from '@utils/constant';
import { getUsersSelector } from '@utils/selectors';
import ContentLoading from '@views_admin/ContentLoading';
import { Dropdown } from 'antd';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';

const _searchInOptionList = (optionList, searchValue) => {
   let results = [];
   optionList.forEach((option) => {
      if (kmpSearch(searchValue, escapesValue(option.email)) !== -1) {
         results = [...results, { ...option }];
      }
   });
   return results;
};

const SelectUsers = memo(({ value, disabled, onChange }) => {
   const [isDropdownOpen, setIsDropdownOpen] = useState(false);
   const [searchValue, setSearchValue] = useState('');
   const innerRef = useRef();
   const canceler = useRef();

   useEffect(() => {
      const loadUsers = async () => {
         canceler.current = await userService.loadUsers({
            page: 1,
            page_size: constant.defaultPagesize,
            order_by: 'updated_at',
            sort_type: 'DESC',
         });
      };
      loadUsers();
      return () => {
         if (canceler.current) {
            canceler.current.cancel();
         }
      };
   }, []);

   const handleSelectOption = useCallback(
      (email) => {
         if (value[email]) {
            const newValue = { ...value };
            delete newValue[email];
            onChange({ ...newValue });
         } else {
            onChange({
               ...value,
               [email]: {
                  email,
               },
            });
         }
      },
      [onChange, value]
   );

   const handleDropdownVisibleChange = useCallback((e) => {
      setIsDropdownOpen(e);
   }, []);

   const deleteOption = (email) => {
      const newValue = { ...value };
      delete newValue[email];
      onChange({ ...newValue });
   };

   const popupStyles = (() => {
      if (innerRef.current) {
         const rect = innerRef.current.getBoundingClientRect().width;
         return {
            width: `${rect}px`,
            maxWidth: `${rect}px`,
         };
      }
      return {};
   })();

   const menu = (() => {
      return (
         <PopupSearchable
            className="pricing-types-dropdown"
            style={popupStyles}
            isOpen={isDropdownOpen}
            scrollContentClassName="pricing-types-dropdown__option-list"
            searchValue={searchValue}
            dataSelector={getUsersSelector}
            setSearchValue={setSearchValue}
            funcSearchInOptionList={_searchInOptionList}
            funcFetchData={userService.loadUsers}
            disabled={disabled}
         >
            {({ optionList, loading }) => (
               <>
                  <ContentLoading isLoading={loading} size="small" />
                  {optionList.length === 0 && !loading && (
                     <div className="empty">No data</div>
                  )}
                  {optionList.map((option) => {
                     const isSelected = value?.[option.email] ? true : false;
                     const optionDisabled = loading;
                     return (
                        <div
                           className={`popup-searchable__item-option multiple ${
                              isSelected ? 'selected' : ''
                           }`}
                           key={option.email}
                           onClick={() =>
                              !optionDisabled &&
                              !disabled &&
                              handleSelectOption(option.email)
                           }
                        >
                           <Checkbox
                              label={option.email}
                              colorActive="#2F80ED"
                              isChecked={isSelected}
                              readOnly={true}
                              disabled={optionDisabled}
                           />
                        </div>
                     );
                  })}
               </>
            )}
         </PopupSearchable>
      );
   })();

   const optionList = useMemo(() => {
      return Object.values(value);
   }, [value]);

   return (
      <Dropdown
         overlayClassName="common-dropdown size-small"
         visible={isDropdownOpen}
         overlay={menu}
         placement="bottomLeft"
         trigger="click"
         onVisibleChange={handleDropdownVisibleChange}
         overlayStyle={popupStyles}
      >
         <div
            className={`fake-input-select ${disabled ? 'disabled' : ''}`}
            ref={innerRef}
         >
            {optionList.length === 0 ? (
               <span className="placeholder">Select users</span>
            ) : (
               <div className="fake-input-select__selected-options">
                  {optionList.map((op) => (
                     <div className="op-item" key={op.email}>
                        <div className="op-item--content">
                           <span>{op.email}</span>
                           {!disabled && (
                              <i
                                 onClick={(e) => {
                                    e.stopPropagation();
                                    deleteOption(op.email);
                                 }}
                              >
                                 <IconCancel12 />
                              </i>
                           )}
                        </div>
                     </div>
                  ))}
               </div>
            )}
            <i className="chevron">
               <IconArrowDown2 />
            </i>
         </div>
      </Dropdown>
   );
});

export default SelectUsers;
