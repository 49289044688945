import React from 'react';

const IconSendMessage = (props) => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M9.35355 7.35355C9.54882 7.15829 9.54882 6.84171 9.35355 6.64645C9.15829 6.45118 8.84171 6.45118 8.64645 6.64645L9.35355 7.35355ZM13.7304 1.0261L13.5879 0.546832L13.7304 1.0261ZM14.9739 2.26961L14.4946 2.12712L14.9739 2.26961ZM2.08005 7.8716L1.9072 8.34077L2.08005 7.8716ZM2.33186 4.93648L13.8729 1.50537L13.5879 0.546832L2.04689 3.97795L2.33186 4.93648ZM14.4946 2.12712L11.0635 13.6681L12.0221 13.9531L15.4532 2.41209L14.4946 2.12712ZM8.59757 13.7471L6.96917 9.32715L6.03083 9.67285L7.65923 14.0928L8.59757 13.7471ZM6.67285 9.03083L2.2529 7.40243L1.9072 8.34077L6.32715 9.96917L6.67285 9.03083ZM6.85355 9.85355L9.35355 7.35355L8.64645 6.64645L6.14645 9.14645L6.85355 9.85355ZM11.0635 13.6681C10.7072 14.8666 9.02982 14.9203 8.59757 13.7471L7.65923 14.0928C8.42397 16.1685 11.3917 16.0735 12.0221 13.9531L11.0635 13.6681ZM13.8729 1.50537C14.2535 1.39221 14.6078 1.74651 14.4946 2.12712L15.4532 2.41209C15.7926 1.27026 14.7297 0.207369 13.5879 0.546832L13.8729 1.50537ZM2.04689 3.97795C-0.0735095 4.60833 -0.168528 7.57603 1.9072 8.34077L2.2529 7.40243C1.07967 6.97018 1.13337 5.29279 2.33186 4.93648L2.04689 3.97795Z"
         fill="currentColor"
      />
   </svg>
);

export default IconSendMessage;
