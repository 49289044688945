import { SortType } from '@apis/enums/SortType';
import IconArrowSort from '@icons/IconArrowSort';
import IconCheck from '@icons/IconCheck';
import { Dropdown, Menu } from 'antd';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';

const Sort = memo(({ orderBy, sortType, options = [], onChange }) => {
   const [value, setValue] = useState(options?.[0]?.value || '');

   useEffect(() => {
      if (orderBy) {
         setValue(orderBy);
      }
   }, [orderBy]);

   const handleChangeOrderby = useCallback(
      (optionValue, isActive) => {
         if (!isActive) {
            onChange(optionValue, sortType || SortType.DESC);
         }
      },
      [onChange, sortType]
   );

   const handleChangeSortType = () => {
      let nextSortType = SortType.DESC;
      if (sortType === SortType.DESC) {
         nextSortType = SortType.ASC;
      } else if (sortType === SortType.ASC) {
         nextSortType = '';
      }
      onChange(nextSortType ? value : '', nextSortType);
   };

   const menu = useMemo(() => {
      return (
         <Menu className="common-menu" style={{ width: '160px' }}>
            {options.map((option) => {
               const isActive = option.value === value;
               return (
                  <Menu.Item
                     key={option.value}
                     className="common-menu-item"
                     onClick={() => handleChangeOrderby(option.value, isActive)}
                  >
                     <span>{option.label}</span>
                     {isActive && (
                        <i className="checked">
                           <IconCheck />
                        </i>
                     )}
                  </Menu.Item>
               );
            })}
         </Menu>
      );
   }, [value, options, handleChangeOrderby]);

   const currentOrderBy = useMemo(() => {
      return options.find((o) => o.value === value)?.label || '';
   }, [options, value]);

   return (
      <div className={`sort-table ${sortType || ''}`}>
         <button className="sort-table__type" onClick={handleChangeSortType}>
            <IconArrowSort />
         </button>
         <Dropdown
            overlayClassName="common-menu-context size-small"
            placement="bottomRight"
            trigger="click"
            overlay={menu}
         >
            <button className="sort-table__order-by">{currentOrderBy}</button>
         </Dropdown>
      </div>
   );
});

export default Sort;
