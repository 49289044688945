import IconEdit from '@icons/IconEdit';
import { utcToCurrent } from '@utils/common';
import { Tooltip } from 'antd';
import React, { memo, useCallback, useMemo } from 'react';
import NotificationDetailsModal from './NotificationDetailsModal';

const EditNoti = memo(({ uid, data, onSuccess }) => {
   const [isOpen, setIsOpen] = React.useState(false);

   const handlerCancel = useCallback(() => {
      setIsOpen(false);
   }, []);

   const handleSuccess = useCallback(() => {
      setIsOpen(false);
      onSuccess(true);
   }, [onSuccess]);

   const initialData = useMemo(() => {
      if (data) {
         const receivers = {};
         if (data?.receivers?.length) {
            data.receivers.forEach((email) => {
               receivers[email] = {
                  email: email,
               };
            });
         }
         return {
            uid: data.uid || '',
            title: data.title || '',
            message: data.message || '',
            all: data.all ?? true,
            receivers: receivers,
            now: false,
            scheduled_at: data.scheduled_at ? utcToCurrent(data.scheduled_at) : '',
         };
      }
      return null;
   }, [data]);

   return (
      <>
         <Tooltip placement="bottom" title="Edit" color="black">
            <button className="button-icon action-normal" onClick={() => setIsOpen(true)}>
               <IconEdit />
            </button>
         </Tooltip>
         <NotificationDetailsModal
            visible={isOpen}
            data={initialData}
            onCancel={handlerCancel}
            onSuccess={handleSuccess}
         />
      </>
   );
});

export default EditNoti;
