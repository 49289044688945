import IconSearch16 from '@icons/IconSearch16';
import { escapesValue, sortByDate } from '@utils/common';
import constant from '@utils/constant';
import React, { forwardRef, memo, useCallback, useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import ScrollLoadable from './ScrollLoadable';

const PopupSearchable = memo(
   forwardRef(
      (
         {
            className,
            style,
            isOpen,
            scrollContentClassName,
            children,
            footer,
            searchValue,
            setSearchValue,
            dataSelector,
            funcSearchInOptionList,
            funcFetchData,
            disabled,
         },
         ref
      ) => {
         const [optionList, setOptionList] = useState([]);

         const dataCanceler = useRef();
         const timerSearch = useRef();
         const searchInput = useRef();

         const { data, loading } = useSelector(dataSelector);
         const { search, totalPages, currentPage, content } = data || {};

         const valueKeeperRef = useRef({
            searchValue: searchValue,
            currentPage: currentPage,
            search: search,
            funcSearchInOptionList: funcSearchInOptionList,
            funcFetchData: funcFetchData,
         });

         useEffect(() => {
            if (isOpen) {
               setTimeout(() => {
                  if (searchInput.current?.focus) {
                     searchInput.current.focus({ preventScroll: true });
                  }
               }, 10);
            }
         }, [isOpen]);

         useEffect(() => {
            valueKeeperRef.current = {
               searchValue: searchValue,
               currentPage: currentPage,
               search: search,
               funcSearchInOptionList: funcSearchInOptionList,
               funcFetchData: funcFetchData,
            };
         });

         useEffect(() => {
            return () => {
               clearTimeout(timerSearch.current);
               if (dataCanceler.current?.cancel) {
                  dataCanceler.current.cancel();
               }
               if (
                  valueKeeperRef.current.currentPage > 1 ||
                  `${valueKeeperRef.current.search}`.trim()
               ) {
                  valueKeeperRef.current.funcFetchData({
                     page: 1,
                     page_size: constant.defaultPagesize,
                     order_by: 'updated_at',
                     sort_type: 'DESC',
                  });
               }
            };
         }, []);

         useEffect(() => {
            if (content) {
               const sortedContent = [...content].sort(sortByDate('updated_at'));
               setOptionList(
                  valueKeeperRef.current.funcSearchInOptionList(
                     sortedContent,
                     valueKeeperRef.current.searchValue
                  )
               );
               if (searchInput.current?.focus) {
                  searchInput.current.focus({ preventScroll: true });
               }
            }
         }, [content]);

         const handleSearch = (value, noDelay) => {
            clearTimeout(timerSearch.current);
            if (dataCanceler.current?.cancel) {
               dataCanceler.current.cancel();
            }
            const refetchData = async () => {
               const searchValue = escapesValue(value);
               if (totalPages <= 1 && currentPage === 1 && !`${search}`.trim()) {
                  // search on fe
                  const sortedContent = [...content].sort(sortByDate('updated_at'));
                  setOptionList(
                     valueKeeperRef.current.funcSearchInOptionList(
                        sortedContent,
                        searchValue
                     )
                  );
               } else {
                  // refetch data from api
                  dataCanceler.current = await funcFetchData({
                     page: 1,
                     page_size: constant.defaultPagesize,
                     order_by: 'updated_at',
                     sort_type: 'DESC',
                     search: searchValue,
                  });
               }
            };
            if (noDelay) {
               refetchData();
            } else {
               timerSearch.current = setTimeout(() => {
                  refetchData();
               }, constant.delaySearch);
            }
         };

         const onSearchChange = (e) => {
            let value = e.target.value;
            setSearchValue(value);
            handleSearch(value);
         };

         const onSearchPressEnter = (e) => {
            if (e.keyCode === 13) {
               handleSearch(searchValue, true);
            }
         };

         const loadNextPage = useCallback(() => {
            if (hasNextPage) {
               dataCanceler.current = funcFetchData({
                  page: currentPage + 1,
                  page_size: constant.defaultPagesize,
                  order_by: 'updated_at',
                  sort_type: 'DESC',
                  search: searchValue,
               });
            }
         }, [searchValue, hasNextPage, currentPage, funcFetchData]);

         const hasNextPage = currentPage && totalPages > currentPage;

         return (
            <div
               className={`common-menu popup-searchable ${className || ''}`}
               style={style || {}}
            >
               <div className="popup-searchable__search-box">
                  <input
                     ref={searchInput}
                     value={searchValue}
                     disabled={loading}
                     placeholder="Search"
                     onChange={onSearchChange}
                     onKeyDown={onSearchPressEnter}
                     onFocus={(e) => e.preventDefault()}
                  />
                  <IconSearch16 />
               </div>
               <ScrollLoadable
                  className={`popup-searchable__option-list ${
                     scrollContentClassName || ''
                  }`}
                  disabled={loading || !hasNextPage}
                  loadMore={loadNextPage}
               >
                  {children({ optionList, loading })}
               </ScrollLoadable>
               {footer && footer}
            </div>
         );
      }
   )
);

export default PopupSearchable;
