import IconFilter from '@icons/IconFilter';
import IconFilterActive from '@icons/IconFilterActive';
import { Dropdown, Menu, DatePicker } from 'antd';
import React, { memo, useCallback, useEffect, useMemo, useState } from 'react';
import moment from 'moment';
import { EventStatus } from '@utils/enums/EventStatus';
import _forIn from 'lodash/forIn';
import Input from '@components/Input';
import { InputType } from '@utils/enums/InputType';
import { _getNumberValue } from '@views_admin/product-info/models/SubmissionModel';
import { NotificationStatus } from '@utils/enums/NotificationStatus';

const { RangePicker } = DatePicker;

const displayFormat = 'DD/MM/YYYY';
const valueFormat = 'YYYY-MM-DD';

const statuses = [
   {
      label: 'Sent',
      value: NotificationStatus.SENT,
   },
   {
      label: 'Scheduled',
      value: NotificationStatus.SCHEDULED,
   },
   {
      label: 'Failed',
      value: NotificationStatus.FAILED,
   },
];

const priorities = [
   {
      label: 'Normal',
      value: 'NORMAL',
   },
   {
      label: 'High',
      value: 'HIGH',
   },
];

const Filter = memo(({ filterSet = {}, onChange }) => {
   const [visible, setVisible] = useState(false);

   const handleChange = useCallback(
      (...args) => {
         onChange(...args);
         setVisible(false);
      },
      [onChange]
   );

   const menu = useMemo(() => {
      return <FilterMenu initialValue={filterSet} onChange={handleChange} />;
   }, [filterSet, handleChange]);

   const isActive = useMemo(() => {
      const filterValueList = Object.values(filterSet);
      if (filterValueList.some((i) => i)) {
         return true;
      }
      return false;
   }, [filterSet]);

   return (
      <Dropdown
         overlayClassName="common-dropdown filter-popup"
         placement="bottomRight"
         trigger="click"
         overlay={menu}
         destroyPopupOnHide={true}
         visible={visible}
         onVisibleChange={(e) => setVisible(e)}
      >
         <div className={`sort-table filter-table ${isActive ? 'active' : ''}`}>
            <button className="sort-table__type">
               {isActive ? <IconFilterActive /> : <IconFilter />}
            </button>
            <button className="sort-table__order-by">Filter</button>
         </div>
      </Dropdown>
   );
});

const FilterMenu = memo(({ initialValue, onChange }) => {
   const [value, setValue] = useState({
      ...initialValue,
   });

   useEffect(() => {
      setValue({ ...initialValue });
   }, [initialValue]);

   const handleChange = useCallback((name, value) => {
      // let filtervalue = value;
      // if (name === 'fromRate' || name === 'toRate') {
      //    filtervalue = _getNumberValue(value);
      // }
      setValue((prevValue) => ({
         ...prevValue,
         [name]: value,
      }));
   }, []);

   const onDateRangeChange = useCallback(
      (_, dateValues) => {
         if (dateValues?.length === 2) {
            const fromDate = dateValues[0]
               ? moment(dateValues[0], displayFormat).format(valueFormat)
               : '';
            const toDate = dateValues[1]
               ? moment(dateValues[1], displayFormat).format(valueFormat)
               : '';
            handleChange('fromDate', fromDate);
            handleChange('toDate', toDate);
         }
      },
      [handleChange]
   );

   const handleClear = () => {
      const newFilter = {};
      _forIn(value, (_, key) => {
         newFilter[key] = '';
      });
      onChange(newFilter);
   };

   const handleFilter = () => {
      onChange({ ...value });
   };

   const getDateInputValue = (value) => {
      if (value) {
         return moment(value, valueFormat);
      }
      return '';
   };

   return (
      <Menu className="common-menu products-filter-menu">
         <label className="filter-label">Status</label>
         <div className="filter-control">
            {statuses.map((item) => {
               const isSelected = item.value === value.status;
               return (
                  <div
                     className={`status-item notis_${item.value} ${
                        isSelected ? 'active' : ''
                     }`}
                     key={item.value}
                     onClick={() => handleChange('status', isSelected ? '' : item.value)}
                  >
                     {item.label}
                  </div>
               );
            })}
         </div>
         <label className="filter-label">Priority</label>
         <div className="filter-control">
            {priorities.map((item) => {
               const isSelected = item.value === value.priority;
               return (
                  <div
                     className={`status-item notis_SCHEDULED ${
                        isSelected ? 'active' : ''
                     }`}
                     key={item.value}
                     onClick={() =>
                        handleChange('priority', isSelected ? '' : item.value)
                     }
                  >
                     {item.label}
                  </div>
               );
            })}
         </div>
         <label className="filter-label">Date</label>
         <div className="filter-control">
            <RangePicker
               value={[
                  getDateInputValue(value.fromDate),
                  getDateInputValue(value.toDate),
               ]}
               placeholder={['From', 'To']}
               suffixIcon={null}
               format={displayFormat}
               onChange={onDateRangeChange}
            />
         </div>
         <div className="filter-actions-bottom">
            <button className="clear" onClick={handleClear}>
               Clear
            </button>
            <button className="apply" onClick={handleFilter}>
               Apply
            </button>
         </div>
      </Menu>
   );
});

export default Filter;
