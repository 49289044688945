import React, { PureComponent } from 'react';

class ScrollLoadable extends PureComponent {
   containerRef = null;
   detectorRef = null;
   loadStarted = false;

   setContainerRef = (r) => {
      this.containerRef = r;
   }
   setDetectorRef = (r) => {
      this.detectorRef = r;
   }

   componentWillUnmount() {
      clearTimeout(this.loadDelay);
      if (this.containerRef) {
         this.containerRef.removeEventListener('scroll', this.detectLoadMore);
      }
   }

   componentDidMount() {
      this.initEventHandler();
   }

   componentDidUpdate(prevProps, prevState) {
      if (prevProps.disabled !== this.props.disabled) {
         this.initEventHandler();
      }
   }

   initEventHandler = () => {
      if (this.containerRef && !this.props.disabled) {
         this.containerRef.addEventListener('scroll', this.detectLoadMore);
      }
   }

   detectLoadMore = () => {
      if (this.detectorRef && this.props.loadMore) {
         clearTimeout(this.loadDelay);
         this.loadDelay = setTimeout(() => {
            const containerRect = this.containerRef.getBoundingClientRect();
            const detectorRect = this.detectorRef.getBoundingClientRect();

            if ((detectorRect.top < containerRect.bottom)) {
               if (!this.loadStarted) {
                  this.loadStarted = true;
                  this.props.loadMore();
               }
            } else {
               this.loadStarted = false;
            }
         }, 10);
      }
   }


   render() {
      const { className } = this.props;
      return (
         <div className={className}
            ref={this.setContainerRef}
         >
            {this.props.children}
            <div ref={this.setDetectorRef} />
         </div>
      );
   }
}

export default ScrollLoadable;