import React, { memo, useCallback, useEffect, useState } from 'react';
import { Button, Col, Form, Row } from 'antd';
import { InputType } from '@utils/enums/InputType';
import Input from '@components/Input';
import SelectUsers from '@views_admin/components/SelectUsers';
import _isEmpty from 'lodash/isEmpty';
import { notificationService } from '@services/notificationService';
import { message } from '@components/Message';
import { convertToDateTime, currentToUtc0, getTimeZone } from '@utils/common';
import moment from 'moment-timezone';
import constant from '@utils/constant';

const fields = [
   {
      label: 'Title',
      name: 'title',
      placeholder: 'Title',
      required: true,
      type: InputType.TEXT,
   },
   {
      label: 'Message',
      name: 'message',
      placeholder: 'Message',
      rows: 3,
      required: true,
      type: InputType.TEXTAREA,
   },
   {
      label: 'Send to',
      name: 'all',
      suffix: 'All',
      type: InputType.SWITCH,
   },
   {
      label: '',
      name: 'receivers',
      placeholder: 'Select receivers',
      component: SelectUsers,
      refItem: 'all',
      displayCondition: (refValue) => !refValue,
      formItemClass: 'sub-item',
   },
   {
      label: 'Send at',
      name: 'now',
      suffix: 'Now',
      type: InputType.SWITCH,
   },
   {
      label: '',
      name: 'scheduled_at',
      refItem: 'now',
      placeholder: 'Select schedule time',
      type: InputType.DATE_TIME,
      displayCondition: (refValue) => !refValue,
      formItemClass: 'sub-item',
      disabledDate: (current) => {
         const now = moment().tz(getTimeZone());
         if (moment(current).isBefore(now)) {
            return true;
         }
         return false;
      },
   },
];

const initialValues = {
   title: '',
   message: '',
   all: true,
   receivers: {},
   now: true,
   scheduled_at: '',
};

const NotiForm = memo(({ data, onCancel, onSuccess }) => {
   const [form] = Form.useForm();
   const [states, setStates] = useState({});
   const [submitting, setSubmitting] = useState(false);
   const [errors, setErrors] = useState({});

   useEffect(() => {
      const formValues = { ...initialValues, ...(data || {}) };
      setStates(formValues);
      form.setFieldsValue(formValues);
      // eslint-disable-next-line react-hooks/exhaustive-deps
   }, [data]);

   const onValuesChange = useCallback((values) => {
      setErrors({});
      setStates((prev) => ({ ...prev, ...(values || {}) }));
   }, []);

   const handleSubmit = useCallback(
      async (values) => {
         await setSubmitting(true);
         let errors = {};
         if (!values.title) {
            errors.title = 'Title is required';
         }
         if (!values.message) {
            errors.message = 'Message is required';
         }
         if (!values.all && (!values.receivers || _isEmpty(values.receivers))) {
            errors.receivers = 'Receivers is required';
         }
         if (!values.now && !values.scheduled_at) {
            errors.scheduled_at = 'Scheduled time is required';
         }
         setErrors(errors);
         if (_isEmpty(errors)) {
            console.log(values);
            const body = {
               ...values,
               receivers: values.all
                  ? []
                  : Object.values(values.receivers || {}).map((item) => item.email),
               scheduled_at: values.now
                  ? ''
                  : currentToUtc0(values.scheduled_at, constant.datetimeValueFormat),
            };
            let response;
            if (data?.uid) {
               response = await notificationService.updateNotification(data.uid, body);
            } else {
               response = await notificationService.createNotification(body);
            }
            if (response.isSuccess) {
               message.success(
                  !body.scheduled_at
                     ? 'Notification has been sent'
                     : `Notification has been scheduled at ${convertToDateTime(
                          body.scheduled_at
                       )}`
               );
               onSuccess();
            } else {
               message.error(response.message);
            }
         }

         setSubmitting(false);
      },
      [data, onSuccess]
   );

   return (
      <div>
         <Form
            form={form}
            initialValues={initialValues}
            className="noti-form"
            onFinish={handleSubmit}
            onValuesChange={onValuesChange}
         >
            <div>
               <label className="noti-form__header">
                  {data?.uid ? 'Edit Notification' : 'Create Notification'}
               </label>
               <div className="noti-form__body">
                  {fields.map((field, index) => {
                     const {
                        label,
                        component,
                        refItem,
                        displayCondition,
                        required,
                        formItemClass,
                        ...fieldProps
                     } = field;
                     let display = true;
                     if (displayCondition) {
                        display = displayCondition(states[refItem]);
                     }
                     if (!display) return null;
                     const RenderedComponent = component || Input;
                     return (
                        <Row
                           key={index}
                           className={`field-data-container-row ${formItemClass || ''}`}
                        >
                           <Col span={24}>
                              <Row className="field-data-row">
                                 <Col lg={4} md={4} sm={4} xs={24}>
                                    <label className="field-title">
                                       {label}{' '}
                                       {required && <span className="required">*</span>}
                                    </label>
                                 </Col>
                                 <Col lg={20} md={20} sm={20} xs={24}>
                                    <Form.Item name={fieldProps.name}>
                                       <RenderedComponent
                                          {...fieldProps}
                                          inputClassName="h40"
                                       />
                                    </Form.Item>
                                 </Col>
                              </Row>
                           </Col>
                           {errors[fieldProps.name] && (
                              <Col span={24}>
                                 <Row className="field-data-row">
                                    <Col lg={4} md={4} sm={4} xs={24}>
                                       <span></span>
                                    </Col>
                                    <Col lg={20} md={20} sm={20} xs={24}>
                                       <span className="error-message">
                                          {errors[fieldProps.name]}
                                       </span>
                                    </Col>
                                 </Row>
                              </Col>
                           )}
                        </Row>
                     );
                  })}
               </div>
               <div className="noti-form__footer">
                  <div className="actions">
                     <button
                        className="btn-vape h40 white-op"
                        type="button"
                        onClick={onCancel}
                     >
                        Cancel
                     </button>
                     <Form.Item>
                        <button
                           className="btn-vape h40 blue1"
                           type="submit"
                           disabled={submitting}
                           onClick={handleSubmit}
                        >
                           {submitting ? 'Saving' : 'Save'}
                        </button>
                     </Form.Item>
                  </div>
               </div>
            </div>
         </Form>
      </div>
   );
});

export default NotiForm;
