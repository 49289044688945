import React from 'react';

const IconCheck = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         d="M3.5 8L6.5 11L12.5 5"
         stroke="currentColor"
         strokeWidth="1.5"
         strokeLinecap="square"
      />
   </svg>
);

export default IconCheck;