import IconAdd2 from '@icons/IconAdd2';
import React, { memo, useCallback } from 'react';
import NotificationDetailsModal from './NotificationDetailsModal';

const AddNoti = memo(({ onSuccess }) => {
   const [isOpen, setIsOpen] = React.useState(false);

   const handlerCancel = useCallback(() => {
      setIsOpen(false);
   }, []);

   const handleSuccess = useCallback(() => {
      setIsOpen(false);
      onSuccess();
   }, [onSuccess]);

   return (
      <>
         <button className="outline-btn btn-add-new-item" onClick={() => setIsOpen(true)}>
            <i>
               <IconAdd2 />
            </i>
            Create Notification
         </button>
         <NotificationDetailsModal
            visible={isOpen}
            onCancel={handlerCancel}
            onSuccess={handleSuccess}
         />
      </>
   );
});

export default AddNoti;
