import React from 'react';

const IconFilterActive = () => (
   <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M13 8C13 8.27614 12.7761 8.5 12.5 8.5H3.5C3.22386 8.5 3 8.27614 3 8C3 7.72386 3.22386 7.5 3.5 7.5H12.5C12.7761 7.5 13 7.72386 13 8Z"
         fill="#2F80ED"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M10 2.5C10 2.77614 9.77614 3 9.5 3H0.5C0.223858 3 0 2.77614 0 2.5C0 2.22386 0.223858 2 0.5 2H9.5C9.77614 2 10 2.22386 10 2.5Z"
         fill="#2F80ED"
      />
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M10 13.5C10 13.7761 9.77614 14 9.5 14H6.5C6.22386 14 6 13.7761 6 13.5C6 13.2239 6.22386 13 6.5 13H9.5C9.77614 13 10 13.2239 10 13.5Z"
         fill="#2F80ED"
      />
      <circle cx={14} cy="2.5" r={2} fill="#FF8C4B" />
   </svg>
);

export default IconFilterActive;
