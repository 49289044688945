import _snakeCase from 'lodash/snakeCase';
import _forIn from 'lodash/forIn';
import _isObject from 'lodash/isObject';
import _isArray from 'lodash/isArray';

class RequestModel {
   constructor(data) {
      this.#initData(data);
   }

   #initData = (data) => {
      if (data) {
         if (_isArray(data)) {
            data.forEach(itemData => {
               this.#buildDataObj(itemData);
            });
         } else {
            this.#buildDataObj(data);
         }
      }
   }

   #buildDataObj = (dataObj) => {
      if (_isObject(dataObj)) {
         _forIn(dataObj, (value, key) => {
            this[_snakeCase(key)] = value ?? '';
         })
      }
   }
}

export default RequestModel;