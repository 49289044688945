import React from 'react';

const IconEdit = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M9.18856 1.9989C10.5175 0.669964 12.6721 0.669962 14.0011 1.9989C15.33 3.32783 15.33 5.48246 14.0011 6.8114L6.39829 14.4142C6.02321 14.7892 5.51451 15 4.98407 15H1.5C1.22386 15 1 14.7761 1 14.5V11.0159C1 10.4855 1.21071 9.97675 1.58579 9.60168L9.18856 1.9989ZM12.1464 7.2518L13.294 6.10429C14.2324 5.16588 14.2324 3.64441 13.294 2.70601C12.3555 1.76759 10.8341 1.76759 9.89567 2.70601L8.74816 3.85352L12.1464 7.2518ZM8.04105 4.56062L11.4393 7.95891L5.69118 13.7071C5.50364 13.8946 5.24929 14 4.98407 14H2V11.0159C2 10.7507 2.10536 10.4963 2.29289 10.3088L8.04105 4.56062Z"
         fill="currentColor"
      />
   </svg>
);

export default IconEdit;