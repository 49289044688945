import React from 'react';

const IconDelete2 = () => (
   <svg
      width={16}
      height={16}
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
   >
      <path
         fillRule="evenodd"
         clipRule="evenodd"
         d="M6 2.5C6 2.22386 6.22386 2 6.5 2H9.5C9.77614 2 10 2.22386 10 2.5V3.5H6V2.5ZM5 3.5V2.5C5 1.67157 5.67157 1 6.5 1H9.5C10.3284 1 11 1.67157 11 2.5V3.5H16V4.5H13.4768L13.0901 12.6189C13.0267 13.9519 11.9275 15 10.593 15H5.40703C4.07255 15 2.97333 13.9519 2.90986 12.6189L2.52324 4.5H0V3.5H5ZM3.52438 4.5H12.4756L12.0913 12.5713C12.0532 13.3711 11.3937 14 10.593 14H5.40703C4.60634 14 3.94681 13.3711 3.90873 12.5713L3.52438 4.5Z"
         fill="currentColor"
      />
   </svg>
);

export default IconDelete2;
