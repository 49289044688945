import React from 'react';

const IconArrowSort = () => (
   <svg width={16} height={16} viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M8 1V15" stroke="currentColor" strokeMiterlimit={10} strokeLinejoin="round" />
      <path
         d="M13 10L8 15L3 10"
         stroke="currentColor"
         strokeMiterlimit={10}
         strokeLinecap="square"
         strokeLinejoin="round"
      />
   </svg>
);

export default IconArrowSort;
