import { SortType } from '@apis/enums/SortType';
import constant from '@utils/constant';
import React, { memo, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { Link } from 'gatsby';
import _capitalize from 'lodash/capitalize';
import _lowerCase from 'lodash/lowerCase';
import { convertToDate } from '@utils/common';
import { createCancelToken } from '@apis/ServiceController';
import RequestModel from '@apis/models/RequestModel';
import { notificationService } from '@services/notificationService';
import ContentLoading from '@views_admin/ContentLoading';
import Search from '@views_admin/layout/Search';
import CommonTable from '@views_admin/components/CommonTable';
import AddNoti from '@views_admin/notifications/AddNoti';
import BtnDelete from '@views_admin/notifications/BtnDelete';
import { NotificationStatus } from '@utils/enums/NotificationStatus';
import EditNoti from '@views_admin/notifications/EditNoti';
import Resend from '@views_admin/notifications/Resend';
import Sort from '@views_admin/components/Sort';
import Filter from '@views_admin/notifications/Filter';
import SEO from '@components/SEO';

const sortOptions = [
   {
      label: 'Last modified',
      value: 'updated_at',
   },
   {
      label: 'Scheduled date',
      value: 'scheduled_at',
   },
   {
      label: 'Date created',
      value: 'created_at',
   },
   {
      label: 'Status',
      value: 'status',
   },
   {
      label: 'Priority',
      value: 'priority',
   },
   {
      label: 'Title',
      value: 'title',
   },
];

const Notifications = memo(() => {
   const [searchText, setSearchText] = useState('');
   const [filter, setFilter] = useState({
      page: 1,
      pageSize: constant.defaultPagesize,
      orderBy: 'updated_at',
      sortType: SortType.DESC,

      status: '',
      priority: '',
      fromDate: '',
      toDate: '',
   });
   const [data, setData] = useState({
      content: [],
      totalRows: 0,
   });
   const [loading, setLoading] = useState(true);
   const cancelToken = useRef();

   const reloadData = useCallback((keepCurrentPage) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: keepCurrentPage === true ? prevFilter.page : 1,
      }));
   }, []);

   const columns = useMemo(() => {
      return [
         {
            title: 'Title',
            dataIndex: 'title',
            render: (text) => <span className="noti-title">{text}</span>,
         },
         {
            title: 'Status',
            dataIndex: 'status',
            render: (text, record) => (
               <span className={`noti-stt ${text}`}>{_capitalize(_lowerCase(text))}</span>
            ),
         },
         {
            title: 'Priority',
            dataIndex: 'priority',
            render: (text, record) => <span>{_capitalize(_lowerCase(text))}</span>,
         },
         {
            title: 'Creator',
            dataIndex: 'creator',
            render: (text, record) => <span>{record?.creator?.username || ''}</span>,
         },

         {
            title: 'Scheduled at',
            dataIndex: 'scheduled_at',
            render: (text, record) => <span>{convertToDate(text)}</span>,
         },
         {
            title: 'Message',
            dataIndex: 'message',
            render: (text) => <p className="noti-message">{text}</p>,
         },
         {
            title: 'Action',
            key: 'action',
            width: 116,
            render: (text, record) => (
               <div className="admin-col-action">
                  {record.status === NotificationStatus.FAILED && (
                     <Resend uid={record.uid} onSuccess={reloadData} />
                  )}
                  {record.status === NotificationStatus.SCHEDULED && (
                     <EditNoti data={record} onSuccess={reloadData} />
                  )}
                  {record.status === NotificationStatus.SCHEDULED && (
                     <BtnDelete uid={record.uid} onSuccess={reloadData} />
                  )}
               </div>
            ),
         },
      ];
   }, [reloadData]);

   useEffect(() => {
      if (cancelToken.current) {
         cancelToken.current.cancel();
      }
      setLoading(true);
      cancelToken.current = createCancelToken();
      const loadData = async () => {
         const requestBody = new RequestModel([
            filter,
            {
               search: searchText.trim(),
            },
         ]);
         const responseData = await notificationService.getNotificationsMatrix(
            requestBody,
            cancelToken.current?.token
         );
         if (responseData.isSuccess) {
            const { content, totalRows } = responseData.data;
            setData((prevData) => ({
               ...prevData,
               content: content || [],
               totalRows: totalRows,
            }));
         }
         setSearchText(searchText.trim());
         setLoading(false);
      };
      loadData();

      return () => {
         if (cancelToken.current) {
            cancelToken.current.cancel();
         }
      };
   }, [filter, searchText]);

   const onTableChange = useCallback(({ pagination, sorter }) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         page: pagination?.page || prevFilter.page,
         pageSize: pagination?.pageSize || prevFilter.pageSize,
      }));
   }, []);

   const handleSort = useCallback((orderBy, sortType) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         sortType,
         orderBy,
      }));
   }, []);

   const handleFilter = useCallback((newFilter = {}) => {
      setFilter((prevFilter) => ({
         ...prevFilter,
         ...newFilter,
         page: 1,
      }));
   }, []);

   const filterSet = useMemo(() => {
      return {
         status: filter.status,
         fromDate: filter.fromDate,
         toDate: filter.toDate,
         priority: filter.priority,
      };
   }, [filter]);

   return (
      <>
         <SEO title={'Notifications - Admin'} />
         <div className="content-head">
            <div className="content-head__left">
               <label className="title">Notifications</label>
            </div>
            <div className="content-head__right">
               <AddNoti onSuccess={reloadData} />
            </div>
         </div>
         <div className="content-body admin-notifications__body">
            <div className="admin-table">
               <ContentLoading isLoading={loading} />
               <div className="admin-table__header">
                  <div className="admin-table__header--left">
                     <Search placeholder="Search..." onSearch={(v) => setSearchText(v)} />
                  </div>
                  <div className="admin-table__header--right">
                     <Filter filterSet={filterSet} onChange={handleFilter} />
                     <Sort
                        orderBy={filter.orderBy}
                        sortType={filter.sortType}
                        options={sortOptions}
                        onChange={handleSort}
                     />
                  </div>
               </div>
               {
                  <CommonTable
                     rowKey="uid"
                     unit="Notifications"
                     dataSource={data.content}
                     columns={columns}
                     page={filter.page}
                     pageSize={filter.pageSize}
                     totalRows={data.totalRows}
                     onChange={onTableChange}
                  />
               }
            </div>
         </div>
      </>
   );
});

export default Notifications;
