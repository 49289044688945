import { message } from '@components/Message';
import IconSendMessage from '@icons/IconSendMessage';
import { notificationService } from '@services/notificationService';
import { Tooltip } from 'antd';
import React, { memo, useCallback, useState } from 'react';

const Resend = memo(({ uid, onSuccess }) => {
   const handleResend = useCallback(async () => {
      if (uid) {
         message.loading('Resending...', 0);
         const responseData = await notificationService.resendNotification(uid);
         setTimeout(async () => {
            if (responseData.isSuccess) {
               if (onSuccess) {
                  onSuccess(true);
               }
               message.success('Resend Successful.');
            } else {
               message.error(responseData.message);
            }
         }, 500);
      }
   }, [uid, onSuccess]);

   return (
      <Tooltip placement="bottom" title="Resend" color="black">
         <button className="button-icon" onClick={handleResend}>
            <IconSendMessage />
         </button>
      </Tooltip>
   );
});

export default Resend;
